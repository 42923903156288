import {React, useEffect} from "react";
import '../App.css';

import FooterView from "../components/FooterView"

const Footer= () =>{
    return(
        <>
           <FooterView/>
        </>
    )
}

export default Footer