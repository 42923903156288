import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import '../App.css';
import './NavbarView.css';

const NavbarView = () =>{
    {/**menu variable  */}
    const [showMenu, setShowMenu] = useState(false);
    const [uppermenu, setUpperMenu] = useState(false)
    
 
   
    
    const controlNavbar = () =>{
        
        if(window.scrollY > window.innerHeight*0.72){
            setUpperMenu(false);
        }
        if(window.scrollY < window.innerHeight*0.72){
            setUpperMenu(false);
        }
        if(window.scrollY > window.innerHeight*0.92){
            document.getElementById('navbar').setAttribute('style','top:0;position:fixed')
            
        }
        
        if(window.scrollY < window.innerHeight*0.92){
            if(window.innerWidth <= 660){
                document.getElementById('navbar').setAttribute('style','top:0;position:fixed')
            
            }
            else if(window.innerHeight <=520){
                document.getElementById('navbar').setAttribute('style','top:0;position:fixed')

            }
            else{
                document.getElementById('navbar').setAttribute('style','top:calc( 100vh - 3.5em);position:absolute')
                
            }
            
        }
        
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }
  
  
    useEffect(()=>{

        
        window.addEventListener('scroll', controlNavbar)
        return ()=>{
            window.removeEventListener('scroll',controlNavbar)
        }
    }, [])
    


    return(
        <div id = "nav">
            
            <div id = "navbar">
            {/* logo */}
            <NavLink to ={"/"} className = "logoName" onClick={()=>document.getElementById('navbar').setAttribute('style','top:calc( 100vh - 4em);position:absolute')}>
                {/*<img src={require("../assets/logo.png")} alt = "concepte"></img>*/}
                <p id = 'headerlogo'>concept<strong>e</strong></p>
            </NavLink>


           <div className="menu-icon" onClick={toggleMenu}>
                <div className="menu-line"></div>
                <div className="menu-line"></div>
                <div className="menu-line"></div>
            </div>
            
            <div className={`navlinks ${showMenu ? 'active' : ''} ${uppermenu ? 'upper':''}`} >
            {/* Link Home */}
            <NavLink className = "navlink" to={"/"} onClick={()=>document.getElementById('navbar').setAttribute('style','top:calc( 100vh - 4em);position:absolute')} >
                HOME
            </NavLink>
            {/* Link Produkt */}
            <Link className = "navlink" to={"/Produkt"}>
                PRODUKT
            </Link>
            {/*  Link Tech */}
            {/*<NavLink className = "navlink" to={"/Tech"}>
                TECH
            </NavLink>*/}
            {/* Link Team */}
            <NavLink className = "navlink" to={"/Team"}>
                TEAM
            </NavLink>
            {/* Link Kontakt */}
            <NavLink className = "navlink" to={"/Kontakt"}>
                KONTAKT
            </NavLink>
            </div>
            
           
            
            </div>
        </div>
    )
}

export default NavbarView;