import {React, useEffect} from "react";

import '../App.css';
import '../components/NavbarView.css';


import NavbarView from "../components/NavbarView";

const Navbar= () =>{
    return(
        <>
           <NavbarView/>
        </>
    )
}

export default Navbar