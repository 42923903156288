import React, {useState, useEffect, useCallback} from "react";
import {useLocation} from 'react-router-dom';
import '../App.css';
import './StartView.css';

const StartView= (props) =>{
    {/* initialize focusword */}
    const [focusword, setFocusword] = useState("Sicherheit")
    const allFocuswords = ["Sicherheit","Nachhaltigkeit", "Wirtschaftlichkeit"]
    {/* initial focusword index */}
    var index = 0;
    
    {/* initialize location */}
    let location =useLocation();


    {/* initialize view of focusword */}
    const startfocusword=()=>{
        {/* get position of focusword */}
        var top= document.getElementById('wrapper_start_focusword').getBoundingClientRect().top ;
        var left= document.getElementById('wrapper_start_focusword').getBoundingClientRect().left;
        
        {/* set position, style and animation of focusword */}
        document.getElementById('focusword').setAttribute('style',`position:absolute;top:${top}px;left:${left}px;animation: move-from-right 2.5s both;visibility:visible`)

        {/* change animation to infinite animation */}
        const interval3 = setTimeout(infinitefocusword,2500)
    }

    const infinitefocusword=()=>{
        {/* get position of focusword */}
        var top= document.getElementById('wrapper_start_focusword').getBoundingClientRect().top ;
        var left= document.getElementById('wrapper_start_focusword').getBoundingClientRect().left;
        
        {/* set position, style and animation of focusword */}
        document.getElementById('focusword').setAttribute('style',`position:absolute;top:${top}px;left:${left}px;animation:none;visibility:visible`)
        document.getElementById('focusword').setAttribute('style',`position:absolute;top:${top}px;left:${left}px;animation: move-constantly 5s infinite both;visibility:visible`)


    }

    {/* initial useEffect */}
    useEffect(()=>{
        {/* set position of navigation bar */}
        if(props.position ==0){
            document.getElementById('navbar').setAttribute('style','top:calc( 100vh - 3.5em);position:absolute')
        }

        {/* startfocusword with delay */}
        const interval2 = setTimeout(startfocusword,2500)
       
        
        return ()  =>{
            clearTimeout(interval2)
          
        } 

    },[])
    
    {/* set focusword */}
    const shuffle = useCallback(()=>{
        
        index = index + 1;
        if(index === 3){
            index = 0;
        }
        setFocusword(allFocuswords[index]);
    },[])


    {/* set interval for change of focusword */}
    useEffect(()=>{
        
        const interval = setInterval(shuffle,2500);
        
        return ()  =>{
            clearInterval(interval)
        } 

    },[shuffle])

    
  
    return(
        <div className = "wrapper_start">
            {/**background logo */}
            <div className= "backgroundlogo">
                <img src={require("../assets/kreis-logo.png")} alt = {"Logo"}></img>
            </div>
            {/**main slogan */}
            <p id = "wrapper_start_mainslogan">Ihr mathematisch <br/> optimiertes Energiesystem <br/> für mehr <span id="wrapper_start_focusword">{focusword}</span> </p>
            <span id="focusword">{focusword}</span> 
        </div> 
    )
}

export default StartView;