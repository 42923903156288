import {React, useEffect} from "react";
import '../App.css';
import DatenschutzView from "../components/DatenschutzView";

const Datenschutz= () =>{

    
   
    return(
        <>
            <DatenschutzView/>
        </>
    )
}

export default Datenschutz;