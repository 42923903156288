import { React, useEffect } from "react";
import '../App.css';
import ImpressumView from "../components/ImpressumView";

const Impressum= () =>{

  
    return(
        <>
            <ImpressumView/>
        </>
    )
}

export default Impressum;