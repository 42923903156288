import React, {useState, useEffect, useCallback} from "react";
import {useLocation} from 'react-router-dom';
import HomeView from "../components/HomeView"
import StartView from '../components/StartView';
import '../App.css';
import '../components/HomeView.css';


const Home= (props) =>{

    {/* initialize location */}
    let location =useLocation();


    {/**scroll if location changes */}
    useEffect(()=>{
        
        document.getElementById(props.position).scrollIntoView()

    },[location])
    

    return(
    
        <div id = "home">
            
            <StartView />
            {/**view everything else and scroll to correct position */}
           <HomeView />
            
        </div>
    )
}

export default Home;