import {React, useEffect} from "react";
import '../App.css';
import '../components/CookieConsentView.css';


import CookieConsentView from "../components/CookieConsentView"

const CookieConsent= () =>{
    return(
        <>
           <CookieConsentView/>
        </>
    )
}
export default CookieConsent