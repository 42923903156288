import {React, useEffect} from "react";
import {NavLink} from "react-router-dom";
import '../App.css';
import './DatenschutzView.css'

const DatenschutzView = () =>{
     const fixNavbar =()=>{
        document.getElementById('navbar').setAttribute('style','top:0;position:fixed')
    }
    useEffect(()=>{
        window.scrollTo(0,0)
        document.getElementById('navbar').setAttribute('style','top:0;position:fixed')
        window.addEventListener('scroll', fixNavbar)
        return ()=>{
            window.removeEventListener('scroll',fixNavbar)
        
        }

    },[])

  
    return(
 
        <div className = "wrapper_light" id= "datenschutz">
            
        <h1 >Datenschutz</h1>
        <p>Die Concept-e (nachfolgend „Verantwortlicher“), freut sich, dass Sie unsere Webseite besuchen. Datenschutz und Datensicherheit bei der Nutzung unserer Webseite sind für uns sehr wichtig. Wir möchten Sie daher an dieser Stelle darüber informieren, welche personenbezogenen Daten wir bei Ihrem Besuch auf unserer Webseite erfassen und für welche Zwecke diese genutzt werden.<br/><br/>
        Da Gesetzesänderungen oder Änderungen unserer unternehmensinternen Prozesse eine Anpassung dieser Datenschutzerklärung erforderlich machen können, bitten wir Sie, diese Datenschutzerklärung regelmäßig durchzulesen. Die Datenschutzerklärung kann jederzeit unter Datenschutzerklärung abgerufen, abgespeichert und ausgedruckt werden.</p>
        <br/><br/>
        <h3>§ 1 Verantwortlicher und Geltungsbereich</h3>
        <br/>
        <p>Der Verantwortliche im Sinne der EU-Datenschutzgrundverordnung (im Folgenden: DSGVO) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:</p>
        <br/>
        <p>
        Joel Rogawski
        <br/>
        Weyertal 109
        <br/>
        50931 Köln
        <br/>
        Deutschland
        <br/>
        E-Mail: info@concept-e.solutions
        <br/>
        Website: www.concept-e.solutions
        </p>
        <br/><br/>
        <p>
        Weitere Angaben können Sie dem Impressum unserer Webseite entnehmen. Diese Datenschutzerklärung gilt für das Internetangebot von Concept-e, welches unter der Domain www.concept-e.solutions sowie den verschiedenen Subdomains (im Folgenden „unsere Webseite“ genannt) abrufbar ist.
        </p>
        <br/> <br/>
        <h3>§ 2 Grundsätze der Datenverarbeitung</h3>
        <br/>
        <p>Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen Webseite sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>
        <br/> <br/>
        <h3>§ 3 Rechtsgrundlagen</h3>
        <br/>
        <p>Die Rechtsgrundlagen für die Verarbeitung von personenbezogenen Daten ergeben sich grundsätzlich aus:</p>
        <ul>
            <li>Art. 6 Abs. 1 lit. a DSGVO, soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen.</li>
            <li>Art. 6 Abs. 1 lit. b DSGVO bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen - Vertragspartei die betroffene Person ist, erforderlich sind. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</li>
            <li>Art. 6 Abs. 1 lit. c DSGVO, soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt.</li>
            <li>Art. 6 Abs. 1 lit. f DSGVO, soweit die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist und die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht überwiegen.</li>
        </ul>
        <p>Für die von uns vorgenommenen Verarbeitungsvorgänge geben wir jeweils die konkreten anwendbaren Rechtsgrundlagen an.</p>
        <br/> <br/>
        <h3>$ 4 Datenlöschung und Speicherdauer</h3>
        <br/>
        <p>Es werden keine personenbezogenen Daten erfasst, der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.</p>
        <br/> <br/>
        <h3>$ 5 Weitergabe von Daten</h3>
        <br/>
        <p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
        <ul>
            <li>Sie hierzu Ihre ausdrückliche Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO erteilt haben</li>
            <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 lit. b DSGVO zur Erfüllung eines Vertragsverhältnisses mit Ihnen erforderlich ist</li>
            <li>nach Art. 6 Abs. 1 lit. c DSGVO für die Weitergabe eine gesetzliche Verpflichtung besteht </li>
            <li>die Weitergabe nach Art. 6 Abs. 1 lit. f DSGVO zur Wahrung berechtigter Unternehmensinteressen, sowie zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.</li>
        </ul>
        <p>Empfänger  der Daten sind:</p>
        <ul>
            <li>EMAILJS PTE. LTD. 139 CECIL STREET, #03-10, YSY BUILDING, SINGAPORE (069539)</li>
            <li>HETZNER ONLINE GMBH, INDUSTRIESTR. 25, 91710 GUNZENHAUSEN, DEUTSCHLAND</li>
        </ul>
        <br/> <br/>
        <h3>§ 6 Voraussetzungen für die Weitergabe von personenbezogenen Daten in Drittländer</h3>
        <br/>
        <p>Im Rahmen unserer Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des Europäischen Wirtschaftsraums (EWR), also in Drittländern, befinden. Bei der Übertragung Ihrer personenbezogenen Daten in Drittländern achten wir auf die Einhaltung der besonderen Voraussetzungen der Art. 44 ff. DSGVO.</p>
        <br/>
        <p>Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen Datenschutz, der dem EWR-Standard vergleichbar ist. In anderen Drittländern, in die ggf. personenbezogene Daten übertragen werden, herrscht aber unter Umständen wegen fehlender gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies über bindende Unternehmensvorschriften, Standardvertragsklauseln der Europäischen Kommission zum Schutz personenbezogener Daten gem. Art. 46 Abs. 1, 2 lit. c DSGVO in Verbindung mit angemessenen Sicherheitsgarantien, Zertifikate oder anerkannte Verhaltenskodizes. </p>
        <br/> <br/>
        <h3>§ 7 Einzelne Verarbeitungsvorgänge</h3>
        <br/>
        <ol>
            <li><strong>Bereitstellung und Nutzung der Webseite</strong></li>
            <br/>

            <p>a. Art und Umfang der Datenverarbeitung</p>
            <br/>
            <p>Bei Aufruf und Nutzung unserer Webseite erheben wir die personenbezogenen Daten, die Ihr Browser automatisch an unseren Server übermittelt. Wenn Sie unsere Webseite nutzen, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Webseite anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:</p>
            <ul>
                <li>der verwendete Browsertyp und die Browserversion, (sofern vom Benutzer übermittelt!)</li>
                <li>das Betriebssystem</li>
                <li>Datum und Uhrzeit der Serveranfrage</li>
                <li>die Anzahl der Besuche</li>
                <li>die Verweildauer auf der Website</li>
                <li>die vorher besuchte Website, (sofern vom Benutzer übermittelt!)</li>  
                <li>die IP-Adresse der Nutzer wird anonymisiert, bevor sie gespeichert wird</li>          
            </ul>
            <br/>
            <p>Diese Informationen werden temporär in einem sog. Logfile gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.</p>
            <br/>
            <p>b. Zwecke und Rechtsgrundlagen</p>
            <br/>
            <p>
                Die vorübergehende Speicherung  der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Webseite an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Webseite sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Webseite und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt. <br/><br/>
                Für die vorübergehende Speicherung der Daten und der Logfiles dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage. Die Erfassung der Daten zur Bereitstellung der Webseite und die Speicherung der Daten in Logfiles ist für den Betrieb der Webseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit. 
            </p>
            <br/>
            <p>c. Speicherdauer</p>
            <br/>
            <p>Sobald die genannten Daten zur Anzeige der Webseite nicht mehr erforderlich sind, werden sie gelöscht. Im Falle der Erfassung der Daten zur Bereitstellung der Webseite ist dies der Fall, wenn die jeweilige Sitzung beendet ist.  Eine weitergehende Speicherung kann im Einzelfall dann erfolgen, wenn dies gesetzlich vorgeschrieben ist. </p>
            <br/>
            <li><strong>Kontaktformular</strong></li>
            <br/>
            <p>a. Art und Umfang der Datenverarbeitung</p>
            <br/>
            <p>Auf unserer Webseite bieten wir Ihnen an, über ein bereitgestelltes Formular mit uns in Kontakt zu treten. Im Rahmen des Absendevorgangs Ihrer Anfrage über das Kontaktformular wird zur Einholung Ihrer Einwilligung auf diese Datenschutzerklärung verwiesen. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben Daten an uns übermittelt und gespeichert.</p>
            <br/>
            <p>Diese Daten sind:</p>
            <br/>
            <ul>
                <li>Name</li>
                <li>E-Mailadresse</li>
                <li>Firma</li>
                <li>Ggf. Telefonnummer</li>
                <li>Ggf. Weitere personenbezogene Daten, die sie uns im Rahmen der Nachrichten mitteilen wollen
                </li>
            </ul>
            <br/>
            <p>Im Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert: </p>
            <br/>
            <ul>
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit</li>
            </ul>
            <br/>
            <p>Bei der Nutzung des Kontaktformulars erfolgt keine Weitergabe Ihrer personenbezogenen Daten an Dritte. </p>
            <br/>
            <p>b. Rechtsgrundlage</p>
            <br/>
            <p>Die Angabe Ihrer E-Mailadresse dient dabei dem Zweck, Ihre Anfrage zuzuordnen und Ihnen antworten zu können.
                Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <br/>
            <p>c. Speicherdauer</p>
            <br/>
            <div id='einwilligungserklärung'>
                <h2> Einwilligungserklärung</h2>
                <p>Mit der Eingabe meiner Daten und der Betätigung des Buttons „Abschicken“ erkläre ich mein Einverständnis zur Verarbeitung meiner E-Mailadresse, Name, Firma, ggf. Telefonnummer und ggf. weiterer personenbezogener Daten, die ich im Rahmen meiner Nachricht mitteile für die Beantwortung meiner Kontaktanfrage.

                Die Einwilligung zur Erhebung der während der Nutzung des Kontaktformulars erhobenen personenbezogenen Daten kann ich jederzeit widerrufen.
                </p>
            </div>
            <br/>
            <p>Die angegebenen Daten aus dem Kontaktformular werden nach dem versandt des Kontaktformulars systemisch nicht gespeichert. Die angegebenen Daten und Nachrichten werden an die Websiteführenden zur Kontaktaufnahme gesendet. Wünscht die kontaktaufnehmende Person die Löschung oder Korrektur der angegebenen Daten aus dem Kontaktformular, kann dies unter Aufforderung erfolgen.</p>
            <br/> <br/>
            <h3>§ 8 Einsatz von Cookies</h3>
            <br/>
            <p>a. Art und Umfang der Datenverarbeitung</p>
            <br/>
            <p>Wir setzten auf unserer Webseite Cookies ein. Cookies sind kleine Textdateien, die im Rahmen Ihres Besuchs unserer Webseiten von uns an den Browser Ihres Endgeräts gesendet und dort gespeichert werden. Einige Funktionen unserer Webseite können ohne den Einsatz technisch notwendiger Cookies nicht angeboten werden. Andere Cookies ermöglichen uns hingegen verschiedene Analysen. Cookies sind beispielsweise in der Lage, den von Ihnen verwendeten Browser bei einem erneuten Besuch unserer Webseite wiederzuerkennen und verschiedene Informationen an uns zu übermitteln. Mithilfe von Cookies können wir unter anderem unser Internetangebot für Sie nutzerfreundlicher und effektiver gestalten, indem wir etwa Ihre Nutzung unserer Webseite nachvollziehen und Ihre bevorzugten Einstellungen (bspw. Länder- und Spracheneinstellungen) feststellen. Sofern Dritte über Cookies Informationen verarbeiten, erheben diese die Informationen direkt über Ihren Browser. Cookies richten auf Ihrem Endgerät keinen Schaden an. Sie können keine Programme ausführen und keine Viren enthalten.</p>
            <br/>
            <p>b. Rechtsgrundlage</p>
            <br/>
            <p>
            Rechtsgrundlage für die Speicherung von Cookies, Geräte-Kennungen und ähnlichen Tracking-Technologien bzw. für die Speicherung von Informationen in der Endeinrichtung des Endnutzers und den Zugriff auf diese Informationen sowie eine anschließende Verwendung der personenbezogenen Daten ist gemäß § 25 Abs. 1 Gesetz zur Regelung des Datenschutzes und des Schutzes der Privatsphäre in der Telekommunikation und bei Telemedien (TTDSG) ihre Einwilligung. Werden die Cookies nicht auf dem Endgerät des Endnutzers gespeichert, ist die Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO erforderlich. 
            <br/>
            Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung technisch notweniger Cookies ist Art. 6 Abs. 1 lit. f DSGVO.

            </p>
            <br/>
            <p>c. Speicherdauer</p>
            <br/>
            <p>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Webseite deaktiviert, können möglicherweise nicht mehr alle Funktionen der Webseite vollumfänglich genutzt werden.</p>
            <br/> <br/>
            <h3>§ 9 Trackung- und Analysetools</h3>
            <br/>
            <p>Derzeit verwenden wir keine Tracking- und Analysetools sondern erfassen ausschließlich technische Cookies, um eine fortlaufende Optimierung und bedarfsgerechte Gestaltung unserer Webseite sicherzustellen. Für die Verarbeitung der Cookies benötigen wir Ihre Einwilligung Grundlage eines von uns auf der Webseite erteilten Hinweises („Cookie-Banner“) nach Art. 6 Abs. 1 lit. a DSGVO. 
            <br/>
            Sie können eine Speicherung von Cookies generell durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen können. 
            </p>
            <br/> <br/>
            <h3>§ 10 Betroffenenrechte</h3>
            <br/>
            <p>Aus der DSGVO ergeben sich für Sie als Betroffener einer Verarbeitung personenbezogener Daten die folgenden Rechte:</p>
            <br/>
            <ul>
                <li><strong>Auskunft:</strong> Gemäß Art. 15 DSGVO können Sie Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen. Sie können insbesondere Auskunft über die Verarbeitungszwecke, die Kategorien der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, über eine Übermittlung in Drittländer oder an internationale Organisationen sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen zu deren Einzelheiten verlangen.</li>
                <li><strong>Berichtigung:</strong> Gemäß Art. 16 DSGVO können Sie unverzüglich die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten verlangen.</li>
                <li><strong>Löschung:</strong> Gemäß Art. 17 DSGVO können Sie die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten verlangen, soweit die Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.</li>
                <li><strong>Einschränkung:</strong> Gemäß Art. 18 DSGVO können Sie die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, wir die Daten nicht mehr benötigen und Sie deren Löschung ablehnen, weil Sie diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen. Das Recht aus Art. 18 DSGVO steht Ihnen auch zu, wenn Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben.</li>
                <li><strong>Datenübertragbarkeit:</strong> Gemäß Art. 20 DSGVO können Sie verlangen, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder Sie können die Übermittlung an einen anderen Verantwortlichen verlangen.</li>
                <li><strong>Widerruf:</strong> Gemäß Art. 7 Abs. 3 DSGVO können Sie Ihre einmal erteilte Einwilligung jederzeit gegenüber uns widerrufen. Dies hat zur Folge, dass wir die auf dieser Einwilligung beruhende Datenverarbeitung für die Zukunft nicht mehr fortführen dürfen.</li>
                <li><strong>Widerspruch:</strong> Gem. Art. 21 DSGVO haben Sie bei der Verarbeitung Ihrer personenbezogenen Daten auf Grundlage berechtigter Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO das Recht, Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im Fall der Direktwerbung besteht für Sie ein generelles Widerspruchsrecht, das von uns ohne Angabe einer besonderen Situation umgesetzt wird.</li>
                <li><strong>Beschwerde:</strong> Gemäß Art. 77 DSGVO steht Ihnen das Recht zu, sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres Arbeitsplatzes oder unseres Unternehmenssitzes wenden. Bei der für uns zuständigen Aufsichtsbehörde handelt es sich um: <br/><br/>Die Landesbeauftragte für den Datenschutz und die Informationsfreiheit 
            Kavalleriestr. 2-4 <br/>
            40213 Düsseldorf<br/>
            poststelle@ldi.nrw.de<br/>
            0211 – 38424-0  <br/>
            </li>

            </ul>
            

        </ol>

    </div>
    )
}

export default DatenschutzView;